header {
  ul {
    li {
      a {
        transition: background-color 0.3s ease, color 0.3s ease; 
        &:hover {
          color: #003DF2 !important;     
          &.insta {           
            svg path {
                stroke: #003DF2 !important;
                transition: stroke 0.3s ease;
              }
          }
          &.whats{
            svg path {
              stroke: #003DF2 !important;
              fill: #003DF2 !important; 
              transition: fill 0.3s ease, stroke 0.3s ease; 
            }
          }
        }
      }
    }
  }
  .mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #3A3A3A;
    z-index: 9999;
    transition: opacity 0.3s ease-in-out;
    text-align: left !important;
  }
  .mobile-menu-overlay .menu-items {
    list-style: none;    
  }
  
  .mobile-menu-overlay .menu-items li {
    margin: 20px 0;
  }
  .mobile-menu-overlay .menu-items li a {  
    text-decoration: none;
  }
  .close-menu {
    background-color: transparent;
    border: none !important;
    cursor: pointer;
  }
  .whatsapp-container {
    background-color: #5CCB68;
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
}
.top-region {
  .bg-webdoor {
    background-image: url('../images/bg-webdoor.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    @media (max-width: 1023px) {
      background-image: url('../images/bg-webdoor-mobile.webp');
      height: 622px;
    }
  }
  @keyframes arrow-bounce {
    0%, 20% {
      transform: translateY(0); /* Posição inicial */
    }
    40% {
      transform: translateY(-50px); /* Sobe 50px */
    }
    60% {
      transform: translateY(-50px); /* Fica no topo por 0.6s */
    }
    80% {
      transform: translateY(0); /* Desce de volta para a posição inicial */
    }
    100% {
      transform: translateY(0); /* Retorna à posição inicial */
    }
  }
  
  .arrow {
    animation: arrow-bounce 2.4s ease-in-out infinite; /* Aplique a animação no loop */
  }
}
.sobre-nos {
  background-color: #F3F3F3;
  &__border-blue {
    border: 1px solid #003DF2;
    transition: background-color 0.3s ease, color 0.3s ease;
    &:hover {
      background-color: #003DF2; 
      color: #ffffff !important; 
    }
  }
  &__border-start {
    border: solid 1px #F3F3F3;
  }
  &__box-position {
    @media (min-width: 1023px) {
      transform: translatey(75px);
    }
  }
}

.diferencials {
  background-color: #3A3A3A;
}

.no-scroll {
  overflow: hidden;
}

.contact{
  background-image: url('../images/bg-contato-1.webp');  
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  min-height: 504px;
  @media (max-width: 1023px) {
    background-image: url('../images/bg-contato-mobile1.webp');  
    background-position: center;
    background-repeat: no-repeat;
  }
  &__button {
    background-color: #FFFFFF1A !important;
    border: solid 1px rgba($color: #ffffff, $alpha: 0.30) !important;
    border-radius: 1000px !important;
    transition: background-color 0.3s ease, color 0.3s ease;
    backdrop-filter: blur(84px);
    &.whats-hover {
      &:hover {
        background-color: #ffffff !important;
        color: #000000 !important;
      }
    }
    &.insta-hover {
      border-radius: 50% !important;
      &:hover {
        color: #003DF2 !important;
        background-color: #fff !important;
        svg path {
          stroke: #003DF2 !important;
        }      
      }
    }
    &.mail-hover {      
      &:hover {
        color: #000000 !important;
        background-color: #fff !important;
        transition: stroke 0.3s ease;
        svg path {
          stroke: #003DF2 !important;
          fill: #003DF2 !important;           
          transition: fill 0.3s ease, stroke 0.3s ease; 
        }      
      }
    }
  }
}
