$base-font-size: 16px;

@function px-to-rem($px) {
  @return $px / $base-font-size * 1rem;
}

// Mixin para gerar as classes de tamanho de fonte e line-height
@mixin generate-font-and-line-sizes($min, $max) {
  @for $i from $min through $max {
    // Classes de fonte (fs-10, fs-11, etc.)
    .fs-#{$i} {
      font-size: px-to-rem($i + 0px);
    }

    // Classes de line-height (lh-10, lh-11, etc.)
    .lh-#{$i} {
      line-height: px-to-rem($i + 0px);
    }

    // Classes para mobile (fs-m-10, lh-m-10, etc.) com !important
    @media (max-width: 767px) {
      .fs-m-#{$i} {
        font-size: px-to-rem($i + 0px) !important;
      }
      .lh-m-#{$i} {
        line-height: px-to-rem($i + 0px) !important;
      }
    }
  }
}

@include generate-font-and-line-sizes(10, 60);

