@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:wght@300..800&display=swap');

body {
    -webkit-font-smoothing: antialiased;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none !important;
}

.font-lato {
  font-family: "Lato", serif;
}

.font-open-sans {
  font-family: "Open Sans", sans-serif;
}

.font-weight-300 {
  font-weight: 300;
}
.text-right {
  text-align: right;
}
.font-weight-400 {
  font-weight: 400;
}

.text-blue {
  color: #003DF2;
}

.text-light-blue {
  color: #3A6AFC;
}

.text-dark {
  color: #3A3A3A;
}

.border-bottom-opacity-30 {
  border-bottom: solid 1px rgba($color: #ffffff, $alpha: 0.3);
}

.rounded-1000 {
  border-radius: 1000px;
}

.border-blue {
  border: 1px solid #003DF2;
}

.text-16 {
  letter-spacing: 0.16em;
}

.text-7 {
  letter-spacing: 0.07em;
}

.text-3 {
  letter-spacing: 0.03em;
}

.text-8 {
  letter-spacing: 0.08em;
}

.font-weight-500 {
  font-weight:500 ;
}

.font-weight-600 {
  font-weight:600 ;
}

.font-weight-700 {
  font-weight:700 ;
}

.font-weight-800 {
  font-weight:800 ;
}
