@font-face {
	font-family: "icons";
	font-display: block;
	src: url("data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAAWcAAsAAAAACIgAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAAFwAAACAIRoluE9TLzIAAAFkAAAAQAAAAGBWT1DqY21hcAAAAaQAAADOAAACXuLlJIRnbHlmAAACdAAAASkAAAFQMlF/VmhlYWQAAAOgAAAAMAAAADZ2zcRIaGhlYQAAA9AAAAAWAAAAJAfRA/hobXR4AAAD6AAAABEAAAA8A+gAAGxvY2EAAAP8AAAADgAAACAEmASYbWF4cAAABAwAAAAeAAAAIAEbAIFuYW1lAAAELAAAAR0AAAHyFNvC+HBvc3QAAAVMAAAATwAAAG87HbHMeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiA2gNIsQCzIwAykWRnYGNiBYiwMnECSi4GbgQfI5mXgA7IZgZAJACWxBhV4nGNgYXBmnMDAysDAVMW0h4GBoQdCMz5gMGRkAooysDIzYAUBaa4pDAcYdD8yMr8AcqNYnMDCjCiKBAEgtgnYeJy1kl0KgkAUhc/olJo/hET01ApyUQqiDwpiup+eWkPbcgV2rt6XCIKC7vANzBm9Z+YwADYAXHIhFjB3GEjdqJpFd7FbdIuB6xR7Kg4yFChRo0WHcTLzzL0MObUKDbVetdcy/D/FgeOIE8eZmo8EW4SI2DfmeQL6eXSw4m+2bz0+l//l91IJTegvMcTMgwfwHPtDn39VtMwPXSWQpFfktrkieqFIaqUSkkqRTrUi122UmLSKvIhOCUiv8BXgqnhkUBwyKkxtMiswT7BdHVgAAHicPYzNTsJAFIV7+zNAhxk6M6W1JRRm6LRGkhqh0CAGF27UFQ/BmpULNr6Mce/GuOQ1fAVfQmKriScn5yZf7jmGadSyvqw3Y2c8GQZkFHqAaiewvG28gaQhLVrfuZwFfZ9aCfxFQ8OaoCmoy6YajCBYw2wD1QYWZf4fBUx/5woAhfrMb56WC1ZmE/OQPizdLh7siDnM6O6CYUdp4WA2aZvdkIBDOPZ7CPVoKDo2xR3mOcDJMRsfvCxoe4fq7ihXCoLICgeIC7VS77SDXV5+v8Rax8+R1pH1ikWZnmfJnuaxSffFTc6Fq0S+vsLxGRHE9VosDHjIHC+IuO/ZSDLbJqK/xS2Z4a2WleTzYZfwMhGcFZG6luNRru+r06eOTo9xmsbwEekfMqErOQAAAHicY2BkYGAA4ilzU7bF89t8ZeBmfgEUiOJ8vK8BQTMwML9gXg+kOBiYQDwAWOQLlHicY2BkYGB+wcCARDIyoAJ+AEVJAtMAAHicY2BgYGB+wUAWAAAyvADsAAAAeJxjYACCFbghAIDACTEAAHicY2BkYGDgZyhlYGIAARDJBYQMDP/BfAYAFXoBnQAAeJxdjr1OwzAUhU/6h2gQAiExm6ULUvoz9gHamQ7Z08RJWyVx5LiVKjEz8xTMPAXPxYl7JSps6fo75x5fG8ADfhCgWwGGvnarhxuqC/dJd8ID8qPwECGehUdUL8JjvGIiHOIJb5wQDG7pjJEJ93CPWrhP/114QP4QHnL6p/CI/pfwGDG+hUNMgtE+NXW70cWxTKxnX2Jt272p1Tyaeb3WtbaJ05nanlV7KhbO5Sq3plIrUztdlkY11hx06qKdc81yOs3Fj1JTYY8Uhn9usYFGgSNKJLBX/h/FTFjvdFphjgizq/6a/dpnEjieGTNbnFlbnDh7Qdchp86ZMahIK3+3S5fchk7jewc6Kf0IO3+rwRJT7vxfPvKvV78w9VNiAAAAeJxtwckNgCAUQMH/cF/RQigKEyMHSAhL/x68OiNKPrP80ygaWjp6BkYmZhZWNnY0h5zF1XBlU6NJ91O9TRQclcBFxhBJ3Dx4rMgLegcMzwA=") format("woff"),
url("data:font/woff2;charset=utf-8;base64,d09GMgABAAAAAAPsAAsAAAAACIgAAAOfAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHIEABmAAhF4KglCCRAE2AiQDPAsgAAQgBYNyB28bmAcRFZxbyX4e2LaQddBVqGd5pY9OclJ1j1lUV0U2BtULUQeAP+lJEcTOq69iSSjggKI3mvJwugC4NrVrqAqHqsuUOZ/4Ls94yH7ueTqsqwHHjFdeIHlL8iFENSdGlU5sNI78KMrABy+Qx3f+YezKi7gLCMB4CMOtuJQcqJ3KnjYYERiTBHEyncb6Sii4jVCTSBSIhy8sIJr4sEhGrngESnYRRqJCoEoNQwgHjoumuejAXe4kYCb6eXrhWTiAIwaMS2TUxdbgrOtP/HsARULU+srgek2ACOABLrtw0FMfXYtAZQwee++mB4AWDTyOqyrUogGt6EDvR/r/H64qUYt6tKADXfkqhodomQQdmh6DXDOAghrZvgm8uEiJkc8gAQ0gCSt41QbmZgYAVSn9JyYnACWqAICjMK4GcLWhAFRCDVAFGaAWEqAeKoAG8IAWqAJaIQI6oAR0gQG6IQf0gAP0cgX4SIo3NF0dAJi16RyzI3lZPcbT1WoMH35418RkpUPOwlXbjujnn/E2X3Hi0C4Dk70RC5cu2ySpztiGXLhxaRxZNnbpxo3ejRfqbFxpuAi5lbHpTnmLD+5cpZt7S5Jm3LlgvuMAjdNzqEY79hx8/jZjK4zN1zy+3zH9JGqeanDuM8SPO83sJ2wMStDKXnBk067q1lt61zKfoGdcJW2O1Eq0jNrMLIYMj7SoiqpaqmyP3/O83Bf6d2vcJwXc09kzQfwQwQuFc07bfelP/5WX0fsLPmVN9GnLsXrIHG+q4lK5wUw7U8PV0MpwrNXBZ7M3L9SM9yt1MIoxPGwUbTTK0DYfNoz9iIAR5z9+D07xVyauqjlcrh7yVSHx7wHaylenx/qKdgFQnKSF5MYBt8qvV9yJvKbZRiIB1PXO9AkOwDDAbw9ouDxfiIgvIc6RE2PwRCoEgaIJkUQPMiJLMM7sTpZoywSPmhKEqQHgqGkDjxkhCJTJEOmZCxlmMxjO63/0GVfaNVbjHG7g4igJyJxVL6DQ4SyKNE9/RAMzkGQwQv1EeLuS2QpLOIkaZ4ZxROEJe9Cctsy+yjJb9qepxmnRaZzDDVwcJYGyMPoCyknxdIECKfI6QqOb64Ulg7mI+gmEtyvBbJHGQg9OWFf3k7YMjyMynS9f1YPmFG2PsK+Qmd1sW106s2n6wb2Z+MaAOnHEk0AiyUhOClISI4lUSJXUSJ00cOlp46RCEn1Cx32jJVNLkSZSFCghT0RAjkaSWAA=") format("woff2");
}

.icon {
	line-height: 1;
}

.icon:before {
	font-family: icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon-thumbs-up-regular:before {
	content: "\f101";
}
